@import "fonts";

// Fonts
$font-family: "CorsaGrotesk", Verdana, sans-serif;
$font-family-bold: "CorsaGrotesk-Bold", Verdana, sans-serif;
$font-family-medium: "CorsaGrotesk-Medium", Verdana, sans-serif;
$font-family-regular: "CorsaGrotesk-Regular", Verdana, sans-serif;
$font-family-black: "CorsaGrotesk-Black", Verdana, sans-serif;
$font-family-light: "CorsaGrotesk-Light", Verdana, sans-serif;
$font-family-thin: "CorsaGrotesk-Thin", Verdana, sans-serif;
$font-family-header: Georgia, Times, Times New Roman, serif;

// Font-size 
$font-size-xxxxl: 2.10em;  // 38px
$font-size-xxxl: 1.88em;  // 34px
$font-size-xxl: 1.55em;  // 28px
$font-size-xl: 1.42em;  // 26px
$font-size-lgxl: 1.30em; // 24px
$font-size-lg: 1.375em;    // 22px
$font-size-md: 1.25em;     // 20px
$font-size: 16px;
$font-size-sm: 0.89em;  // 16px
$font-size-xs: 14px;
$font-size-xxs: 12px;

// Colors
$txt-color: #0E1833;
$nav-color: #ffffff;
$link-color: #1F55E6;

$base-color: #1F55E6;
$color-2: #15DD95;
$color-3: #FBFBFF;
$color-4: #E5EBEF;
$color-5: #FA4460;
$color-6: #F2F5FD;
$color-7: #8EA5F8;
$color-8: #071B3C;
$color-9: #E4EAEE;
$color-10: #B8B8DE;

$overlay: rgba(0, 0, 0, 0.5);
$content-bg: rgba(255, 255, 255, 0.15);
$box-bg: rgba(255, 255, 255, 0.25);

$modalWhiteOverlay: rgba(0, 0, 0, 0.5);
$modalShadowColor: rgba(0, 0, 0, 0.18);

// Layout
/*
Typical resolutions for some popular screen sizes:
19-inch screen (standard ratio): 1280 x 1024 pixels.
20-inch screen (standard ratio): 1600 x 1200 pixels.
22-inch screen (widescreen): 1680 x 1050 pixels.
24-inch screen (widescreen): 1900 x 1200 pixels.
*/

$bp-desktop: 1900px;
$bp-laptop: 1440px;
$bp-responsive: 1280px;
$bp-tablet: 1024px;
$bp-tablet-sm: 900px;
$bp-mobile: 768px;
$bp-mobile-sm: 680px;

$header-height: 85px;
$header-height-m: 75px;
$header-topspace: 135px;
$container-width:  1690px ;
$container-width-2:  1440px ;
$content-width:  1130px ;

$sidespace-xs: 10px;
$sidespace-sm: 15px;
$sidespace: 25px;
$sidespace-md: 30px;
$sidespace-lg: 45px;
$sidespace-xl: 50px;
$sidespace-xxl: 65px;
$sidespace-xxxl: 75px;

$border-radius: 20px;
$border-radius-sm: 10px;

//forms
$txt-form-color: #4e4e4e;
$color-error: red;
$form-control-height: 40px;

// styles
$txt-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
$img-shadow: 3px 3px 6px 0px rgba(66,84,101,.3);
$box-shadow: 3px 3px 10px 0px rgba(184,184,222,.3);
$box-shadow-2: 0px 2px 10px 0px rgba(0,0,0,.07);
$box-link-shadow: 0px 1px 6px 0px rgba(0,0,0,.07);
$header-shadow: 0px 3px 6px 0px rgba(0,0,0,.15);
// @include box-shadow($box-link-shadow);

$animation: all 0.5s ease-in-out;

// cross-brawser styles

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
} //  @extend %flex;
%flex-inline {
    display: inline-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-inline-flex;
} //  @extend %flex-inline;

%col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    max-width: 100%;
    flex-wrap: wrap;
}

// %animation {
//     -webkit-transition: all 0.5s ease-in-out;
//     -moz-transition: all 0.5s ease-in-out;
//     -ms-transition: all 0.5s ease-in-out;
//     -o-transition: all 0.5s ease-in-out;
//     transition: all 0.5s ease-in-out;
// }

%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
} //  @extend %ellipsis;

%background-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
} //  @extend %background-cover;

/* image fit to box */

%img-fitin-box {
    position: absolute;
    z-index: 0;
    display: block;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    overflow: hidden;
} // @extend %img-fitin-box;

%img-fitin {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    // max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    // max-width: none;
    z-index: -1000;
    overflow: hidden;
}
/*end image fit to box */

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
    transition: $property;
} // .clasname { @include transition(all 0.5s ease-in-out); }

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
} // @include transform( translateX(-50%));

@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -moz-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
} // @include box-shadow($box-shadow);

@mixin text-shadow($property) {
    -webkit-text-shadow: $property;
    -moz-text-shadow: $property;
    -ms-text-shadow: $property;
    text-shadow: $property;
} // @include text-shadow($txt-shadow);
